import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconLocation48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" 
             height="48" 
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M36.2183 27.7932L36.8644 26.7191C38.2199 24.4655 39 21.8269 39 19C39 10.7157 32.2843 4 24 4C15.7157 4 9 10.7157 9 19C9 21.8269 9.78009 24.4655 11.1356 26.7191L11.7817 27.7932L24 44.5989L36.2183 27.7932ZM25.6177 45.775C24.819 46.8735 23.181 46.8735 22.3823 45.775L9.27757 27.75H9.42178C9.40715 27.7257 9.39258 27.7013 9.37808 27.6769C9.34425 27.62 9.31075 27.563 9.27757 27.5057C8.9502 26.9402 8.65447 26.3542 8.3928 25.75C7.49668 23.6808 7 21.3984 7 19C7 9.61116 14.6112 2 24 2C33.3888 2 41 9.61116 41 19C41 21.3984 40.5033 23.6808 39.6072 25.75C39.3455 26.3542 39.0498 26.9402 38.7224 27.5057C38.6893 27.563 38.6557 27.62 38.6219 27.6769C38.6074 27.7013 38.5928 27.7257 38.5782 27.75H38.7224L25.6177 45.775Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M24 25C27.3137 25 30 22.3137 30 19C30 15.6863 27.3137 13 24 13C20.6863 13 18 15.6863 18 19C18 22.3137 20.6863 25 24 25ZM24 27C28.4183 27 32 23.4183 32 19C32 14.5817 28.4183 11 24 11C19.5817 11 16 14.5817 16 19C16 23.4183 19.5817 27 24 27Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconLocation24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width={props.width || '24'}
             height={props.height || '24'}
             viewBox="0 0 24 24"
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M17.7232 13.0358L17.8224 12.8875C18.5662 11.7763 19 10.4418 19 9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9C5 10.4418 5.43376 11.7763 6.17757 12.8875L6.27683 13.0358L6.34365 13.1249L6.35005 13.1336C6.38009 13.1746 6.41056 13.2152 6.44146 13.2555L6.44805 13.2641L12 20.6667L17.5519 13.2641L17.5585 13.2555C17.5894 13.2152 17.6199 13.1746 17.6499 13.1336L17.6563 13.1249L17.7232 13.0358ZM19.2628 14.3162C19.2243 14.3688 19.1851 14.421 19.1454 14.4727L12.8 22.9333C12.4 23.4667 11.6 23.4667 11.2 22.9333L4.85455 14.4727C4.81487 14.421 4.77573 14.3688 4.73715 14.3162L4.5 14H4.51555C4.51382 13.9974 4.51209 13.9948 4.51036 13.9922C4.5069 13.9871 4.50345 13.9819 4.5 13.9767C4.09403 13.3661 3.76063 12.7031 3.51212 12C3.18046 11.0617 3 10.0519 3 9C3 4.02944 7.02944 0 12 0C16.9706 0 21 4.02944 21 9C21 10.0519 20.8195 11.0617 20.4879 12C20.2394 12.7031 19.906 13.3661 19.5 13.9767C19.4966 13.9819 19.4931 13.9871 19.4896 13.9922C19.4879 13.9948 19.4862 13.9974 19.4845 14H19.5L19.2628 14.3162Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M12 11.5C13.1046 11.5 14 10.6046 14 9.5C14 8.39543 13.1046 7.5 12 7.5C10.8954 7.5 10 8.39543 10 9.5C10 10.6046 10.8954 11.5 12 11.5ZM12 13.5C14.2091 13.5 16 11.7091 16 9.5C16 7.29086 14.2091 5.5 12 5.5C9.79086 5.5 8 7.29086 8 9.5C8 11.7091 9.79086 13.5 12 13.5Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconLocation16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props} 
             width="16" 
             height="16" 
             viewBox="0 0 16 16" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M11.2714 8.30481L11.3276 8.22081C11.7523 7.58633 12 6.82512 12 6C12 3.79086 10.2091 2 8 2C5.79086 2 4 3.79086 4 6C4 6.82512 4.24767 7.58633 4.67238 8.22081L4.72861 8.30482L4.76459 8.35279L4.771 8.36152C4.78818 8.38497 4.80561 8.4082 4.82328 8.43124L4.82987 8.43983L8 12.6667L11.1701 8.43983L11.1767 8.43124C11.1944 8.40817 11.2118 8.38494 11.229 8.36153L11.2354 8.3528L11.2714 8.30481ZM12.8419 9.54413C12.8162 9.57921 12.7901 9.614 12.7636 9.64849L8.8 14.9333C8.4 15.4667 7.6 15.4667 7.2 14.9333L3.23637 9.6485C3.20991 9.614 3.18382 9.57921 3.1581 9.54413L3 9.33333H3.01037C3.00921 9.33161 3.00806 9.32988 3.0069 9.32816C3.0046 9.32471 3.0023 9.32125 3 9.31779C2.60418 8.72248 2.31185 8.05244 2.14868 7.33333C2.05137 6.90452 2 6.45826 2 6C2 2.68629 4.68629 0 8 0C11.3137 0 14 2.68629 14 6C14 6.45826 13.9486 6.90452 13.8513 7.33333C13.6881 8.05244 13.3958 8.72248 13 9.3178C12.9977 9.32125 12.9954 9.32471 12.9931 9.32816C12.9919 9.32988 12.9908 9.33161 12.9896 9.33333H13L12.8419 9.54413Z" 
                  fill={props.fill || 'currentColor'}/>
            <path d="M10 6C10 7.10457 9.10457 8 8 8C6.89543 8 6 7.10457 6 6C6 4.89543 6.89543 4 8 4C9.10457 4 10 4.89543 10 6Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}