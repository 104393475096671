export const YOUTUBE_THUMBNAIL_IMAGE_MAX_RES = function (key: string): string {
    return `https://img.youtube.com/vi/${key}/maxresdefault.jpg`;
};
export const YOUTUBE_THUMBNAIL_IMAGE_DEFAULT = function (key: string): string {
    return `https://img.youtube.com/vi/${key}/default.jpg`;
};

export const YOUTUBE_VIDEO_URL = function (key: string, seekToSeconds = 0): string {
    const minutes = (seekToSeconds / 60).toFixed(0);
    const seconds = (seekToSeconds % 60).toFixed(0);
    return `https://www.youtube.com/embed/${key}?t=${minutes}m${seconds}s?vq=hd720`;
};
