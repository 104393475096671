import { NavigationDataGroup } from '@cineamo/legacy-frontend-lib/src/types/NavigationDataItem.type';

import { IconExplore24 } from '@cineamo/legacy-frontend-lib/src/icons/categories/ic-explore';
import { IconFilmSerie24 } from '@cineamo/legacy-frontend-lib/src/icons/categories/ic-filmSerie';
import { IconCinemaChain24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-cinemaChain';
import { IconDistributor24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-distributor';
import { IconProfile24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-profile';

export const distributorNavigation = (): NavigationDataGroup[] => {
    return [
        {
            label: 'navigation.distributor-distribution',
            children: [
                {
                    title: 'navigation.distributor-my-distribution',
                    href: '/settings',
                    icon: <IconDistributor24 className="flex-none" />,
                    necessaryPermission: 'distributor_administration'
                },
                {
                    title: 'navigation.distributor-my-movies',
                    href: '/movie-copyrights',
                    icon: <IconFilmSerie24 className="flex-none" />,
                    necessaryPermission: 'distributor_administration'
                },
                {
                    title: 'navigation.distributor-movie-lists',
                    href: '/movie-lists',
                    icon: <IconExplore24 className="flex-none" />,
                    necessaryPermission: 'distributor_administration'
                }
            ]
        },

        {
            label: 'navigation.distributor-cinemas',
            children: [
                {
                    title: 'navigation.distributor-cinemas',
                    href: '/cinemas',
                    icon: <IconCinemaChain24 />,
                    necessaryPermission: 'distributor_administration'
                }
            ]
        },

        // TODO: Do we need that? Where are the mockups? What should this page contain?
        // {
        //     label: 'navigation.distributor-marketing-and-analytics',
        //     children: [
        //         {
        //             title: 'navigation.distributor-analytics',
        //             href: `/${distributorIdOrSlug}/analytics`,
        //             icon: <IconAcademy24 />,
        //             necessaryPermission: 'admin_administration'
        //         }
        //     ]
        // },

        {
            label: 'navigation.distributor-organization',
            children: [
                {
                    title: 'navigation.distributor-user-administration',
                    href: '/users',
                    icon: <IconProfile24 />,
                    necessaryPermission: 'distributor_administration'
                }
            ]
        }
    ];
};
