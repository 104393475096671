export const VIMEO_THUMBNAIL_IMAGE_MAX_RES = function (key: string): string {
    return `https://vumbnail.com/${key}.jpg`;
};
export const VIMEO_THUMBNAIL_IMAGE_DEFAULT = function (key: string): string {
    return `https://vumbnail.com/${key}_large.jpg`;
};

export const VIMEO_VIDEO_URL = function (key: string, seekToSeconds = 0): string {
    const minutes = (seekToSeconds / 60).toFixed(0);
    const seconds = (seekToSeconds % 60).toFixed(0);
    return `https://player.vimeo.com/video/${key}?controls=0&dnt=0&quality=720p&#t=${minutes}m${seconds}s`;
};
