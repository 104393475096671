import classnames from 'classnames';
import Image from 'next/image';
import React, { SyntheticEvent } from 'react';

export type IconButtonProps = {
    className?: string;
    icon: string | JSX.Element | StaticImageData;
    onClick?: (e: SyntheticEvent) => void;
    type?: 'button' | 'submit' | 'reset';
    style?: ButtonWithIconStyle;
    disabled?: boolean;
    accessibilityName?: string;
};

export enum ButtonWithIconStyle {
    default,
    transparent,
    cyan,
    delete,
    onWhite,
    onWhiteDark,
    ghost
}

function ButtonWithIcon(props: IconButtonProps): JSX.Element {
    const { className, icon, onClick, type, style, accessibilityName } = props;

    const disabled = props.disabled === true;

    let buttonStyle: string;

    let hoverEffect: string;

    switch (style) {
        case ButtonWithIconStyle.transparent:
            hoverEffect = 'hover:opacity-80 hover:bg-gray-light hover:bg-opacity-20';
            buttonStyle = `bg-transparent ${disabled ? '' : hoverEffect}`;
            break;
        case ButtonWithIconStyle.ghost:
            hoverEffect = 'hover:opacity-80 hover:bg-gray-light hover:bg-opacity-20';
            buttonStyle = `bg-white-20 ${disabled ? '' : hoverEffect}`;
            break;
        case ButtonWithIconStyle.cyan:
            hoverEffect = 'text-darkBlue hover:opacity-80';
            buttonStyle = `bg-cyan bg-opacity-100 ${disabled ? '' : hoverEffect}`;
            break;
        case ButtonWithIconStyle.onWhite:
            hoverEffect = 'text-darkBlue hover:opacity-80';
            buttonStyle = `bg-cyan-middle bg-opacity-100 ${disabled ? '' : hoverEffect}`;
            break;
        case ButtonWithIconStyle.onWhiteDark:
            hoverEffect = 'text-white hover:opacity-80';
            buttonStyle = `bg-darkBlue bg-opacity-50 ${disabled ? '' : hoverEffect}`;
            break;
        case ButtonWithIconStyle.delete:
            hoverEffect = 'text-darkBlue hover:opacity-80';
            buttonStyle = `bg-red bg-opacity-100 ${disabled ? '' : hoverEffect}`;
            break;
        default:
            hoverEffect = 'hover:text-white hover:opacity-80';
            buttonStyle = `bg-darkBlue bg-opacity-20 text-white ${disabled ? '' : hoverEffect}`;
            break;
    }

    return (
        <button
            disabled={disabled}
            className={classnames(
                className,
                buttonStyle,
                disabled ? 'opacity-50 cursor-not-allowed' : 'active:opacity-50',
                'transition-all duration-150',
                'w-48 h-48 m-0 rounded-4 cursor-pointer flex-none flex justify-center items-center'
            )}
            onClick={(e) => {
                if (!disabled) {
                    onClick && onClick(e);
                }
            }}
            type={type}
            aria-label={accessibilityName}>
            {icon && typeof icon === 'string' ? (
                <div className="w-32 h-32 relative my-auto mr-10 justify-center cursor-text">
                    <Image
                        layout="fill"
                        src={icon}
                        alt=""
                        sizes="100%"
                        objectFit="contain"
                    />
                </div>
            ) : icon && React.isValidElement(icon) ? (
                <div className="flex items-center">{icon}</div>
            ) : null}
        </button>
    );
}

export default ButtonWithIcon;
