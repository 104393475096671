import {CustomSVGProps} from '../../types/CustomSVGProps.types';

export function IconBack48(props: CustomSVGProps) : JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M9.58582 24L32.2929 1.29289L33.7071 2.7071L12.4142 24L33.7071 45.2929L32.2929 46.7071L9.58582 24Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconBack24(props: CustomSVGProps) : JSX.Element {
    return (
        <svg {...props}
             width="24" 
             height="24" 
             viewBox="0 0 24 24" 
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M5.58582 12L14.2929 3.29289L15.7071 4.70711L8.41424 12L15.7071 19.2929L14.2929 20.7071L5.58582 12Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconBack16(props: CustomSVGProps) : JSX.Element {
    return (
        <svg {...props}
             width="16" 
             height="16" 
             viewBox="0 0 16 16" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M3.58582 8L9.29292 2.29289L10.7071 3.70711L6.41424 8L10.7071 12.2929L9.29292 13.7071L3.58582 8Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}