import { CSSProperties } from 'react';

export enum StyleFadeDirection {
    Top = 'top',
    Right = 'right',
    Bottom = 'bottom',
    Left = 'left',
    Horizontal = 'horizontal',
    Vertical = 'vertical',
    RightTop = 'right top'
}

export function styleFaded(direction: StyleFadeDirection, percentage = 0, transparency = 100): CSSProperties {
    let maskString: string;

    switch (direction) {
        case StyleFadeDirection.Horizontal:
            maskString = `linear-gradient(to right, rgb(0, 0, 0, ${
                (100 - transparency) / 100
            }) 0%, rgb(0, 0, 0) ${percentage}%, rgb(0, 0, 0) ${100 - percentage}%, rgb(0, 0, 0, ${
                (100 - transparency) / 100
            }) 100%)`;
            break;
        case StyleFadeDirection.Vertical:
            maskString = `linear-gradient(to bottom, rgb(0, 0, 0, ${(100 - transparency) / 100}) 0%, rgb(0, 0, 0) ${
                percentage / 2
            }%, rgb(0, 0, 0) ${100 - percentage / 2}%, rgb(0, 0, 0, ${(100 - transparency) / 100}) 100%)`;
            break;
        case StyleFadeDirection.RightTop:
            maskString = `linear-gradient(to right top, rgba(0, 0, 35, ${transparency / 100}), transparent ${
                100 - percentage
            }% 100%)`;
            break;
        default:
            maskString = `linear-gradient(to ${direction}, rgb(0, 0, 0, 1) ${100 - percentage}%, rgba(0, 0, 0, ${
                (100 - transparency) / 100
            }) 100%)`;
            break;
    }

    return {
        maskImage: maskString,
        WebkitMaskImage: maskString
    };
}
