import classnames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';

import { IconBack16 } from '../../../icons/arrows/ic-back';
import { IconForth16 } from '../../../icons/arrows/ic-forth';

function TmpPaginator(props: {
    className?: string;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    pageCount: number;
    totalItems: number;
}): JSX.Element {
    const { className, page, setPage, pageCount } = props;

    if (pageCount < 2) {
        return null;
    }

    return (
        <div className={classnames(className, 'w-full flex flex-row justify-center')}>
            <div className="flex flex-row min-w-215 items-center justify-between">
                <IconBack16
                    className={classnames('flex-none', { 'clickable-element': page > 1 }, { 'opacity-50': page <= 1 })}
                    onClick={() => (page > 1 ? setPage(page - 1) : null)}
                />

                <div className="flex flex-row space-x-12 mx-auto">
                    {page > 2 && (
                        <>
                            <span
                                className="clickable-element"
                                onClick={() => setPage(1)}>
                                1
                            </span>
                            <span>..</span>
                        </>
                    )}
                    {page > 1 && (
                        <span
                            className="clickable-element"
                            onClick={() => setPage(page - 1)}>
                            {pageCount > 1 ? page - 1 : 1}
                        </span>
                    )}
                    <span className="font-montserrat-bold">{page}</span>
                    {page < pageCount && (
                        <span
                            className="clickable-element"
                            onClick={() => setPage(page + 1)}>
                            {pageCount > 1 ? page + 1 : null}
                        </span>
                    )}

                    {page < pageCount - 1 && (
                        <>
                            <span>..</span>
                            <span
                                className="clickable-element"
                                onClick={() => setPage(pageCount)}>
                                {pageCount}
                            </span>
                        </>
                    )}
                </div>

                <IconForth16
                    className={classnames(
                        'flex-none',
                        { 'clickable-element': page < pageCount },
                        { 'opacity-50': page >= pageCount }
                    )}
                    onClick={() => (page < pageCount ? setPage(page + 1) : null)}
                />
            </div>
        </div>
    );
}

export default TmpPaginator;
