import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconSearch48(props: CustomSVGProps) : JSX.Element{
    return (
        <svg {...props}
             width="48" height="48" 
             viewBox="0 0 48 48" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M19 33C26.732 33 33 26.732 33 19C33 11.268 26.732 5 19 5C11.268 5 5 11.268 5 19C5 26.732 11.268 33 19 33ZM19 35C27.8366 35 35 27.8366 35 19C35 10.1634 27.8366 3 19 3C10.1634 3 3 10.1634 3 19C3 27.8366 10.1634 35 19 35Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M40.3137 41.3136L29 29.9999L30.4142 28.5857L41.7279 39.8994L40.3137 41.3136Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconSearch24(props: CustomSVGProps) : JSX.Element{
    return (
        <svg {...props}
             width="25" 
             height="25" 
             viewBox="0 0 25 25" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M23.2929 24.7072L16.2929 17.7072L17.7072 16.293L24.7072 23.293L23.2929 24.7072Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconSearch16(props: CustomSVGProps) : JSX.Element{
    return (
        <svg {...props}
             width="16"
             height="16" 
             viewBox="0 0 16 16" 
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M6.5 11C8.98528 11 11 8.98528 11 6.5C11 4.01472 8.98528 2 6.5 2C4.01472 2 2 4.01472 2 6.5C2 8.98528 4.01472 11 6.5 11ZM6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M14.293 15.7072L9.29294 10.7072L10.7072 9.29297L15.7072 14.293L14.293 15.7072Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}